
:focus { 
    outline: none;
}
.ui.selectable.table tbody tr.shortlist:hover {
    background: #eceff1 !important
}

tbody > tr {
    border: #eeeeee;
    border-width: 0 0 1px 0;
    border-style: solid;
}